// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const EditMerchantPOS = () => {
  const currentLanguageCode = localStorage.getItem('EASY_MOBILITY_LANGUAGE') || 'en-us';
  const intl = useIntl();
  const history = useHistory();
  const { id } = useParams();
  
  // State variables
  const [merchent_ID, setMerchantId] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [pos_user_name, setPosUserName] = useState('');
  const [pos_name, setPosName] = useState('');
  const [pos_pass, setPosPass] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pos_serial_number, setPosSerialNumber] = useState('');
  const [sam_UID, setSamUID] = useState('');
  const [sim_MSIDN, setSimMSIDN] = useState('');
  const [pos_user_limit, setUserLimit] = useState('');
  const [merchant_pos_status, setMerchantPosStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMerchantsDataLoading, setIsMerchantsDataLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let userPersonalData = '';
  let userAuthData = '';
  const authData = localStorage.getItem('authData');
  
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userPersonalData = parsedAuthData.userData;
    userAuthData = parsedAuthData.userToken;
  }

  const fetchMerchantPOS = async () => {
    setIsFormLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/getMerchantPOS`;
      const response = await axios.post(
        url,
        { PersonID: id },
        {
          headers: {
            'access-token': userAuthData?.access_token,
            uid: userAuthData?.uid,
            client: userAuthData?.client,
          },
        }
      );
      const merchantPOS = response.data.data;
      setPosName(merchantPOS.email); 
      setPosPass(merchantPOS.pass);
      setPosSerialNumber(merchantPOS.pos_serial_number);
      setSamUID(merchantPOS.sam_uid);
      setSimMSIDN(merchantPOS.sim_msidn);
      setMerchantId(merchantPOS.merchent_id);
      setPosUserName(merchantPOS.user_name);
      setUserLimit(merchantPOS.user_limit);
      setMerchantPosStatus(merchantPOS.status);
    } catch (error) {
      setError(`Failed to fetch merchant POS [${error}]`);
      toast.error(`Failed to fetch merchant POS [${error}]`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } finally {
      setIsFormLoading(false);
    }
  };

  const fetchStatuses = async () => {
    setIsStatusLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/getStatus`;
      const response = await axios.get(url);
      setStatuses(response.data.data);
    } catch (error) {
      setError(`Failed to get Status [${error}]`);
      toast.error(`Failed to get Status [${error}]`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } finally {
      setIsStatusLoading(false);
    }
  };

  const fetchMerchants = async () => {
    setIsMerchantsDataLoading(true);
    setError(null);
    const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/getAllMerchants?status=1&page=1&pageSize=230`;
    try {
      const response = await axios.get(url, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setMerchants(response.data.data.result || []);
    } catch (error) {
      console.log('API ERROR', error);
      toast.error(`Sorry, ${error}. Please try again later or refresh the page. Code: #r1`, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsMerchantsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchStatuses();
    fetchMerchants();
    fetchMerchantPOS();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if userLimit is greater than 0
    if (pos_user_limit < 1) {
      setError(currentLanguageCode === 'en-us' ? `Limit should be greater than 0` : `الحد المسموح يجب أن يكون أكبر من 0`);
      toast.error(currentLanguageCode === 'en-us' ? `Limit should be greater than 0` : `الحد المسموح يجب أن يكون أكبر من 0`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/updateMerchantPOS`;
      const response = await axios.post(
        url,
        {
          Email: pos_name.trim(),
          Pass: pos_pass.trim(),
          pos_Serial_number: pos_serial_number.trim(),
          sam_UID: sam_UID.trim(),
          sim_MSIDN: sim_MSIDN.trim(),
          merchent_ID: merchent_ID,
          USER_NAME: pos_user_name.trim(),
          USER_LIMIT: pos_user_limit,
          STATUS: merchant_pos_status,
          PersonID: id
        },
        {
          headers: {
            'access-token': userAuthData?.access_token,
            uid: userAuthData?.uid,
            client: userAuthData?.client,
          },
        }
      );

      toast.success('Merchant POS updated successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        toastStyle: {
          backgroundColor: '#F1662D',
          color: 'white',
        },
      });

      /*setTimeout(() => {
        history.push('/CardsManagement/merchantPOS/');
      }, 500); */

    }catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        if (String(error.response.data.message).includes("merchant_pos_user_name_key")) {
          setError(currentLanguageCode == 'en-us' ? `POS Name Is Already Exist` : `اسم الجهاز موجود من قبل`);
          toast.error(currentLanguageCode == 'en-us' ? `POS Name Is Already Exist` : `اسم الجهاز موجود من قبل`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else if (String(error.response.data.message).includes("unique")) {
          setError(currentLanguageCode == 'en-us' ? `You Are Trying Enter Data already Exist` : `تم ادخال هذة البيانات من قبل`);
          toast.error(currentLanguageCode == 'en-us' ? `You Are Trying Enter Data already Exist` : `تم ادخال هذة البيانات من قبل`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
          setError(error.response.data.message);
          toast.error(`Failed to update merchant POS [${error.response.data.message}]`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      } else {
        setError(`Failed to update merchant POS [${error}]`);
        toast.error(`Failed to add merchant POS [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: -5, padding: 4 }}>
        <Typography sx={{ color: '#252733', fontWeight: 'bold', marginBottom: '16px' }}>
          {intl.formatMessage({ id: 'EditMerchantPOS' })}
        </Typography>

        {isFormLoading ? (
          <CircularProgress style={{ display: "block" }} />
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <div className='parent-form'>
              <div>
                <label className='labelStyle'>{intl.formatMessage({ id: 'Name' })}</label>
                <input
                  type="text"
                  className='inputStyle'
                  id="email"
                  value={pos_name}
                  onChange={(e) => setPosName(e.target.value)}
                  required
                />

                <label className='labelStyle'>{intl.formatMessage({ id: 'POSName' })}</label>
                <input
                  type="text"
                  className='inputStyle'
                  id="username"
                  value={pos_user_name}
                  onChange={(e) => setPosUserName(e.target.value)}
                  required
                />

                <label className='labelStyle'>{intl.formatMessage({ id: 'Password' })}</label>
                <div className="inputPasswordContainer">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='inputStylePassword'
                    id="password"
                    value={pos_pass}
                    onChange={(e) => setPosPass(e.target.value)}
                    required
                  />
                  <IconButton
                  className='visibIcon'
                    onClick={() => setShowPassword(!showPassword)}
               style={currentLanguageCode === "en-us" ? {right:"33px"}:{left:"33px"}}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  </div>
                
                <label className='labelStyle'>{intl.formatMessage({ id: 'SelectMerchant' })}</label>
                {isMerchantsDataLoading ? (
                  <CircularProgress size={24} sx={{ display: "block" }} />
                ) : (
                  <select
                    className='inputStyle'
                    id="merchantSelect"
                    value={merchent_ID}
                    onChange={(e) => setMerchantId(e.target.value)}
                    required
                  >
                    {merchants.map((merchant) => (
                      <option key={merchant.merchant_id} value={merchant.merchant_id}>
                        {currentLanguageCode === "en-us" ? merchant.merchant_en_name : merchant.merchant_name}
                      </option>
                    ))}
                  </select>
                )}
                <label className='labelStyle'>{intl.formatMessage({ id: 'MerchantStatus' })}</label>
                {isStatusLoading ? (
                  <CircularProgress size={24} sx={{ display: "block" }} />
                ) : (
                  <select
                    className='inputStyle'
                    id="merchantStatus"
                    value={merchant_pos_status}
                    onChange={(e) => setMerchantPosStatus(e.target.value)}
                    required
                  >
                    {
                      statuses.map((status) => (
                        <option key={status.status_id} value={status.status_id}>
                          {currentLanguageCode === 'en-us' ? status.status_en_name : status.status}
                        </option>
                      ))
                    }
                  </select>
                )}
              </div>
              <div>
                <label className='labelStyle'>{intl.formatMessage({ id: 'POSSerialNumber' })}</label>
                <input
                  type="text"
                  className='inputStyle'
                  id="posSerialNumber"
                  value={pos_serial_number}
                  onChange={(e) => setPosSerialNumber(e.target.value)}
                  required
                />

                <label className='labelStyle'>{intl.formatMessage({ id: 'SAM UID' })}</label>
                <input
                  type="text"
                  className='inputStyle'
                  id="samUID"
                  value={sam_UID}
                  onChange={(e) => setSamUID(e.target.value)}
                  required
                />

                <label className='labelStyle'>{intl.formatMessage({ id: 'SIM MSIDN' })}</label>
                <input
                  type="text"
                  className='inputStyle'
                  id="simMSIDN"
                  value={sim_MSIDN}
                  onChange={(e) => setSimMSIDN(e.target.value)}
                  required
                />

                <label className='labelStyle'>{intl.formatMessage({ id: 'UserLimit' })}</label>
                <input
                  type="number"
                  className='inputStyle'
                  id="userLimit"
                  value={pos_user_limit}
                  onChange={(e) => setUserLimit(e.target.value)}
                  required
                  min="1"
                />
              </div>
            </div>
            <div sx={{ marginBottom: 2, display: "block" }}>
              <button
                type="submit"
                className='updateBtnStyle'
                disabled={isLoading}
              >
                {isLoading ? intl.formatMessage({ id: 'Updating' }) : intl.formatMessage({ id: 'update' })}
              </button>
              <button
                className='cancelBtnStyle'
                onClick={() => history.push('/CardsManagement/merchantPOS/')}
              >
                {intl.formatMessage({ id: 'Back' })}
              </button>
            </div>
          </Box>
        )}
      </Box>
      <ToastContainer />
    </>
  );
};

export default EditMerchantPOS;
