import { LOCALES } from './locales-constants';

export default {
  [LOCALES.ENGLISH]: {
    operations: 'Operations',
    configuration: 'Configuration',
    zoning:'Fare Products',
    welcome: 'Welcome',
    selectStatement: 'Select one of the below systems to proceed',
    login: 'Login',
    logout: 'Logout',
    eazyMobility: 'Eazy Mobility',
    routes: 'Routes',
    englishName: 'English Name',
    arabicName: 'Arabic Name',
    code: 'Code',
    groups: 'Groups',
    organization: 'Organization',
    customerSupport:"Customer Support",
    fareMediaInquery:"Fare Media Inquiry",
    actions: 'Actions',
    addRoute: 'Add Route',
    loginHint: 'Please login to access your account',
    userName: 'Username',
    password: 'Password',
    addOrganization: 'Add Organization',
    pageNotFound: 'Page Not Found',
    notExist: 'Sorry, the page you’re looking for doesn’t exist.',
    goToHome: 'Go To Home',
    status: 'Status',
    drivers: 'Drivers',
    admins: 'Admins',
    organizations: 'Organizations',
    delete: 'Delete',
    confirmDeleteMessage: 'Are you sure you would like to delete',
    route: 'Route',
    cancel: 'Cancel',
    enabled: 'Enabled',
    update: 'Update',
    edit: 'Edit',
    editRoute: 'Edit Route',
    createRoute: 'Create New Route',
    create: 'Create',
    routeInfo: 'Route Info',
    noGroups: 'No groups assigned to this route yet.',
    back: 'Back',
    saveGroups: 'Save Groups',
    createOrganization: 'Add Organization',
    editOrganization: 'Edit Organization',
    oranizationInfo: 'Organization Info',
    name: 'Name',
    lastLogin: 'Last Login',
    addAdmin: 'Add Admin',
    editAdmin: 'Edit Admin',
    email: 'Email',
    phone: 'Phone',
    firstName: 'First Name',
    lastName: 'Last Name',
    repeatPassword: 'Repeat Password',
    somethingWrong: 'Sorry, something went wrong!',
    mwaslatMasrSuperDASH: 'Mwasala.Tech Super Dash',
    adminInfo: 'Admin Info',
    masterConfiguration: 'Master Configuration',
    component: 'Component',
    permissionType: 'Permission Type',
    
    save: 'Save',
    operationsOrganizationLevel: 'Operations Organization Level',
    requiredValidation: 'This Field is required',
    passwordMatchValidation: 'Password must match',
    validEmailValidation: 'Please enter a valid email',
    addDriver: 'Add Driver',
    searchByName: 'Search by name',
    driverInfo: 'Driver Info',
    nationalId: 'National ID',
    licenseNumber: 'License Number',
    backToDriversList: 'Back To Drivers List',
    driver: 'Driver',
    import: 'Import',
    export: 'Export',
    successEditDriverMessage: 'You have successfully updated driver!',
    editDriver: 'Edit Driver',
    createDriver: 'Add Driver',
    organizationId: 'organization Id',
    show: 'Show:',
    jumpToPage: 'Jump to page',
    addToGroups: 'Add To Groups',
    clearGroups: 'Clear Groups',
    enable: 'Enable',
    disable: 'Disable',
    add: 'Add',
    reset: 'reset',
    filter: 'Filter',
    filters: 'Filters',
    group: 'Group',
    all: 'All',
    apply: 'Apply',
    disabled: 'Disabled',
    confirmenableMessage: 'Are you sure you want to enable selected',
    confirmdisableMessage: 'Are you sure you want to disable selected',
    confirmassignMessage: 'Are you sure you want to assign to groups selected',
    confirmdeleteMessage: 'Are you sure you want to delete selected',
    confirmclearGroupsMessage:
      'Are you sure you want to clear groups for selected',
    confirm: 'Confirm',
    noOrganization: 'No organizations found',
    selectAll: 'Select All',
    noGroupsFound: 'No groups found',
    successAddDriver: 'You have successfully added driver!',
    successAddRoute: 'You have successfully added route!',
    successAddOrganization: 'You have successfully added organization!',
    successAddAdmin: 'You have successfully added admin!',
    successEditDriver: 'You have successfully edited driver!',
    successEditRoute: 'You have successfully edited route!',
    successEditOrganization: 'You have successfully edited organization!',
    successEditAdmin: 'You have successfully edited admin!',
    nationalIdMaxValidationMsg: 'Must be maximum 15 digits',
    digitsOnly: 'Must be only digits',
    phoneMaxValidationMsg: 'Must be maximum 11 digits',
    editProfile: 'Edit Profile',
    changePassword: 'Change Password',
    editMyProfile: 'Edit My Profile',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    changePasswordRoles:
      'In order to protect your account, your password must have:',
    passwordCharacterLength: '6 or more characters',
    passwordValidationLetter: 'At least one letter',
    passwordValidationDigits: 'At least one number',
    successdeletedriver: 'The Driver has been deleted successfully',
    noResultYet: 'No Results yet',
    noResultNote: 'Once you add, you’ll see them here.',
    addBtn: 'Add',
    backToOrganizationList: 'Back To Organization List',
    backToRoutesList: 'Back To Route List',
    regeneratePassword: 'Regenerate Password',
    successRegeneratedPassword: 'You have succesfully regenerated password!',
    clearAll: 'Clear All',
    restricted: 'Restricted',
    fares: 'Fares',
    financialControl: 'Financial Control',
    digitalSignage: 'Digital Signage',
    helpDesk: 'Help Desk',
    editAdminInfo: 'Edit Admin Info',
    resetPassword: 'Reset Password',
    theNewPasswordMustHave: 'The new password must have',
    sixOrMoreCharacters: '6 or more characters',
    atLeastOneLetter: 'at least one letter',
    atLeastOneNumber: 'at least one number',
    grantFullControl: 'Grant Full Control',
    denyAllAccess: 'Deny All Access',
    editPermissions: 'Edit Permissions',
    noAccess: 'No Access',
    fullAccess: 'Full Control',
    readOnly: 'Read Only',
    NoAccess: 'No Access',
    FullAccess: 'Full Control',
    ReadOnly: 'Read Only',
    adminPermissions: 'Admin Permissions',
    admin: 'Admin',
    successUpdatePermissions: 'You have successfully updated permissions!',
    successGrantFullControl: 'You have successfully granted full control!',
    successDenyAllAccess: 'You have successfully denied all access!',
    superAdmin: 'Super Admin',
    superadmin: 'Super Admin',
    axes: 'Axes',
    tripSales: 'Trip Sales',
    addTripSale: 'Add Trip Sale',
    ticketSerial: 'Ticket Serial',
    axis: 'Axis',
    stop: 'Stop',
    vehicle: 'Vehicle',
    timeAndDate: 'Time & Date',
    amount: 'Amount',
    trip: 'Trip',
    fromDate: 'From Date',
    toDate: 'To Date',
    fromdate: 'From Date',
    todate: 'To Date',
    addAxis: 'Add Axis',
    selectAStop: 'Select a stop',
    addStop: 'Add Stop',
    stops: 'Stops',
    price: 'Price',
    successdeleteaxis: 'The Axis has been deleted successfully',
    successAddAxis: 'The Axis has been added successfully',
    successEditAxis: 'The Axis has been edited successfully',
    editAxis: 'Edit Axis',
    dragRowsToReorder: 'Drag rows to reorder',
    priceEGP: 'Price (EGP)',
    _stops: 'Stops',
    priceNumberValidation: 'Price must be numbers only',
    nextPage: 'Next Page',
    previousPage: 'Previous Page',
    nogroup: 'No Groups',
    noorganization: 'No Organizations',
    createAdmin: 'Create Admin',
    passwordRulesNotMatchMessage:
      'Password must be at least 6 characters and contain at least one letter',
    plateNumber: 'Plate Number',
    addVehicle: 'Add Vehicle',
    editVehicle: 'Edit Vehicle',
    vehicleInfo: 'Vehicle Info',
    type: 'Type',
    vehicles: 'Vehicles',
    backToVehiclesList: 'Back To Vehicles List',
    color: 'Color',
    modelYear: 'Model Year',
    successEditVehicle: 'You have successfully edited vehicle!',
    successAddVehicle: 'You have successfully added vehicle!',
    successdeletevehicle: 'You have successfully deleted vehicle!',
    vehicleType: 'Vehicle Type',
    vehicleTypes: 'Vehicle Types',
    searchByVehicleCodeOrPlateNumber: 'Search by Vehicle Code or Plate Number',
    vehicleCode: 'Vehicle Code',
    vehicleModelYear: 'Vehicle Model Year',
    noGroupFound: 'No Group Found',
    noOrganizationFound: 'No Organization Found',
    devices: 'Devices',
    ip: 'IP',
    serial: 'Serial',
    deviceInfo: 'Device Info',
    device: 'Device',
    backToDevicesList: 'Back To Devices List',
    addDevice: 'Add Device',
    editDevice: 'Edit Device',
    successEditDevice: 'You have succussfully edited device!',
    successAddDevice: 'You have succussfully added device!',
    successdeletedevice: 'You have succussfully deleted device!',
    deviceType: 'Device Type',
    deviceTypes: 'Device Types',
    searchByDeviceCodeOrSerial: 'Search by Device Code or Serial',
    devicesManagement: 'Devices Management',
    createDevice: 'Create Device',
    unassigned: 'Unassigned',
    vehiclesManagement: 'Vehicles Management',
    successdeleteroute: 'The Route has been deleted successfully',
    adminManagement: 'Admin Management',
    addGroup: 'Add Group',
    successAddGroup: 'You have successfully added group!',
    successEditGroup: 'You have successfully edited group!',
    editGroup: 'Edit Group',
    successdeletegroup: 'The Group has been deleted successfully',
    backToGroupsList: 'Back To Groups List',
    groupInfo: 'Group Info',
    minLengthOneValidation: 'This Field must have at least 1 item',
    stations: 'Stations',
    station: 'Station',
    addStation: 'Add Station',
    editStation: 'Edit Station',
    stationInfo: 'Station Info',
    stationName: 'Station Name',
    successAddStation: 'You have successfully added station!',
    successEditStation: 'You have successfully edited station!',
    successdeletestation: 'You have successfully deleted station!',
    noStationsFound: 'No Stations Found',
    noStations: 'No Stations',
    backToStationsList: 'Back To Station List',
    assignToGroup: 'Assign To Group',
    unAssignFromGroups: 'Unassign From Groups',
    codeNumberValidation: 'Code must be numbers only',
    codeLengthValidation: 'Code must equal 3 digits',
    editStop: 'Edit Stop',
    longitude: 'Longitude',
    latitude: 'Latitude',
    lonNumberValidation: 'Longitude must be numbers only',
    latNumberValidation: 'Latitude must be numbers only',
    confirmunAssignFromGroupsMessage:
      'Are you sure you want to unassign from groups selected',
    successEditStop: 'You have successfully edited stop!',
    successAddStop: 'You have successfully added stop!',
    successdeletestop: 'You have successfully deleted stop!',
    assigned: 'Assigned',
    assignDeviceToVehicle: 'Assign Device To Vehicle',
    changeDevice_serial: 'Change Device {serial}',
    fromVehicle_Code_to: 'From Vehicle Code {from} to',
    assignDevice_serial: 'Assign Device {serial}',
    toVehicleCode: 'To Vehicle Code',
    assignedTo_code: 'Assigned To {code}',
    assignDeviceToVehicleMsg:
      'Are you sure you want to change device {serial} from vehicle code {currentVehicleCode} to {code}',
    stationList: 'Station List',
    vehicleClass: 'Vehicle Class',
    vehicleClasses: 'Vehicle Classes',
    addVehicleClass: 'Add Vehicle Class',
    editVehicleClass: 'Edit Vehicle Class',
    vehicleClassInfo: 'Vehicle Class Info',
    successAddVehicleClass: 'You have successfully added vehicle class!',
    successEditVehicleClass: 'You have successfully edited vehicle class!',
    successdeletevehicleClass: 'You have successfully deleted vehicle class!',
    noVehicleClassFound: 'No Vehicle Class Found',
    noVehicleClassesFound: 'No Vehicle Classes Found',
    backToVehicleClassesList: 'Back To Vehicle Classes List',
    avgSpeedNumberValidation: 'Average Speed must be numbers only',
    averageSpeed: 'Average Speed',
    addVehicleType: 'Add Vehicle Type',
    editVehicleType: 'Edit Vehicle Type',
    vehicleTypeInfo: 'Vehicle Type Info',
    successAddVehicleType: 'You have successfully added vehicle type!',
    successEditVehicleType: 'You have successfully edited vehicle type!',
    successdeletevehicleType: 'You have successfully deleted vehicle type!',
    noVehicleTypeFound: 'No Vehicle Type Found',
    noVehicleTypesFound: 'No Vehicle Types Found',
    backToVehicleTypesList: 'Back To Vehicle Types List',
    capacity: 'Capacity',
    capacityValidation: 'Capacity must be numbers only',
    searchByVehicleCode: 'Search by Vehicle Code',
    vehicletype: 'Vehicle Type',
    vehicleclass: 'Vehicle Class',
    selectAGroup: 'Select a group',
    organizationAccess: 'Organization Access',
    searchByCode: 'Search by Code',
    searchByVehicleCodeOrSerial: 'Search by Vehicle Code or Serial',
    deviceIdentifier: 'Device Identifier',
    ipIsInvalid: 'IP is invalid',
    changeDeviceOrgMsg:
      'If you changed the organization of the device, the current vehicle will be unassigned from the device',
    changeVehicleOrgMsg:
      'If you changed the organization of the vehicle, the vehicle will be unassigned from the current device',
    cash: 'Cash',
    card: 'Card',
    cashPriceEGP: 'Cash Price (EGP)',
    cardPriceEGP: 'Card Price (EGP)',
    successChangePassword: 'You have successfully changed your password!',
    importMissingField: 'The field "{fieldName}" is missing',
    groupNotFound: 'Group "{groupName}" not found for this organization',
    importErrorAtRecord: 'Error at row number {rowNum}: {error}',
    importRemoteErrorAtRecord: 'Error at {rowNum}: {error}',
    importFileTooBig3MB: 'File size is bigger than 3 MB',
    vehicleTypeNotFound: 'Vehicle Type not found',
    successImportMessage: 'You have successfully imported {count} records',
      app_title: "Mwasalat Misr",
      language: "Language",
      welcome_message: "Welcome",
      days_since_release: "it's been {{number_of_days}} days since this video was released",
      NoDateAvailable: "No Data Available",
      Routes: "Routes",
      SearchByName: "Search By Name , Code",
      Show: "Show : ",
      Login: " Login ",
      Logout: " Logout",
      LoginAgain: "You Should Login Again",
      ArabicName: "Arabic Name",
      EnglishName: "English Name",
      Organization: "Organization ",
      Groups: " Groups",
      From: " From",
      Code: " Code",
      WelcomeTo: "Welcome To",
      Username: "User Name",
      Password: "Password",
      PleaseLoginToAccessYourAccount: "Please Login To Access Your Account",
      RouteInfo: "Route Information",
      Axes: "Axes List",
      Cash: "Cash",
      Reset: "Reset",
      clearAll2: "Reset",
      Card: "Card",
      CashPrice: "Cash Price ",
      CardPrice: "Card Price",
      AxisInfo: " Axis Info",
      Stops: "Stops",
      Save: "Save",
      Edit: "Edit",
      Stop: "Stop",
      BackToHome: "Back",
      PageNotFound: "Page Not Found ",
      MakeSureYouHaveOpPermission: "Make Sure You Have Needed Operations Sub Systems Permissons",
      YouAreNotAllowedToDoThis:"You Are Not Allowed To Do This",
      YouHaveNoAccess:"You Have No Access",
      ObjWSIdNotFound:"Object with the specified ID not found.",
      ThisActionNeedToGrantControlForRoutesOfTheOrganisationFirst:"This Action Need To Grant Control For Routes Of The Organisation First",
      ThisActionNeedToDenyControlForFaresOfTheOrganisationFirst:"This Action Need To Deny Control For Fares Of The Organisation First",
      Grant:"Grant",
      Deny:"Deny",
      Atten:"Attention Please",
      CantGetImportantUsedDataFromGroupsApi:"Can't Get Important Used  Data From Groups Api , Please Back And Try Again",
      NoMessageFound:"Waiting...",
      someThingWentWrongError:" Something Went Wrong , Please Back And Try Again",
      noteFaresSybPermissonsChangedToBeLikeRoutesPermissions:" Warning : Any Changes In Routes Permissions May Be Affect On Fares Permissions ",
      errorWhenApplyingPermissionsInGrantFaresReadonlyAccessPleaseTryAgain:"Error When Applying Permissions In Grant Fares Readonly Access , Please  Add It Manually",
      errorWhenApplyingPermissionsInGrantFaresFullControlAccessPleaseTryAgain:"Error When Applying Permissions In Grant Fares Full Access , Please  Add It Manually",
      errorWhenApplyingPermissionsInRoutesPleaseTryAgain:"Error When Applying Permissions In Routes, Please  Add It Manually",
      AnErrorHappend:" There Is An Error Happend",
      StopsNumber: " Stops Number",
      Back: "Back",
      StopsInfo: " Stops Info",
      Filter: "Filter",
      Apply: "Apply",
      Delete: "Delete",
      zones: "Zones",
      zone: "Zone",
      Name: "Name",
      AddZone: " Add Zone",
      NameCode: "Name (Code)",
      BaseFare: "Base Fare (Cash / Card)",
      CuSym: " L.E ",
      ZoneInfo: "Zone Info",
      ThisStopIsIn: "This Stop Is In",
      loginErr1: "You Should Enter Username And Password",
      loginErr2: "Error In User Name Or Password",
      loginSuccess: "Login Successfully",
      Cancel: "Cancel",
      Add: "Add",
      RemoveMarker: "Remove",
      WaitMessage: "Wait for the data to load, if it takes too long, reload the page",
      SearchByFareMediaSerial:"Enter Serial Of Fare Media",
      transID:"Transaction Number",
      ticketPrice:"Amount",
      ticketDate:" Date & Time ",
      driverName:"Driver Name",
      driverUserName:"Driver Username",
      axisName:" Axis Name",
      deviceSerialNumber:"Device Serial Number",
      endDate:"End Date",
      startDate:"Start Date",
      transactionsNumber:"Total Count :",
      transactionsTotalAmount:"Total Amount :",
      ResetFilter: "Reset Filter",
      NoFareMediaExist:"No Data Available For This Serial",
      serial_num:"Serial Number",
      uid:"UID",
      parent_wallet_balance:"Balance",
      fare_media_type_name:"Type",
      is_blacklisted:"Blacklisted",
      issuer_name:"Issuer",
      account_id:"Account Number",
      init_balance:"Initial Balance",
      org_name:"Organization",
      created_at:"Creation Date",
      updated_at:"Last Update At",
      yes:"Yes",
      no:"No",
      tickets:"Tickets",
      charging:"Charging",
      deviceLimitErrorMsg:"Sorry, you have reached your limit",
      user_name:"Owner Name",
      user_phone:"Owner Phone",
      user_email:"Owner Email",
      expiration_date:"Expiration Date",
      AFCReports:"AFC Reports",
      CardsReports:"Cards Reports",
      OperationsReports:"Operations Reports",
      CardsManagement:"Cards Management",
      Merchants:"Merchants",
      MerchantPOS:"Merchant POS",
      ChargingTransactions:"Charging Transactions",
      AddNewMerchant:"Add New Merchant",
      AddMerchant:"Add Merchant",
      MerchantNameEn:"Merchant Name (English)",
      MerchantNameAr:"Merchant Name (Arabic)",
      MerchantStatus:"Status",
      Adding:"Adding...",
      Update:"Edit",
      EditMerchant:"Edit Merchant",
      Updating:"Updating...",
      AddNewMerchantPOS:"Add New Merchant POS",
      AddMerchantPOS:"Add Merchant POS",
      EditMerchantPOS:"Edit Merchant POS",
      UserLimit:"Limit",
      ConfirmPassword:"Confirm Password",
      SelectMerchant:"Select Merchant",
      POSName:"POS Name",
      POSCount:"POS Count",
      Of:"of",
      MerchantInfo:"Merchant Info",
      BackToMerchants:"Back To Merchants",
      MerchantName:"Merchant Name",
      MerchantPOSName:"Name",
      SearchByPOSName:"Search By POS Name",
      MerchantPOSInfo:"Merchant POS Info",
      BackToMerchantPOS:"Back To Merchant POS",
      POSStatus:"POS Status",
      MerStatus:"Merchant Status",
      StartDate:"Start Date",
      EndDate:"End Date",
      FareMediaSerial:" Serial Number",
      TransactionNumber:"Transaction Number",
      FareMediaAmount:"Fare Media Balance",
      SearchByTransactionNumber:"Search By Transaction Number",
      POSSerialNumber:"POS Serial Number",
      FareMedia:"Fare Media",
      AddFareMedia:"Add Fare Media",
      AddNewFareMedia:"Add New Fare Media",
      SellingPrice:"Selling Price",
      DownloadTemplate:"Download Template",
      UploadFile:"Upload File",
      Uploading:"Uploading ...",
      deviceSN:"Device SN",
      NewBalance:"New Balance",
      SN:"SN",
      Vat:"Vat",
      TotalFees:"Fees",
       PaidAmount:"Paid Amount",
       SearchBySerial:"Search By Serial Number",
       selling_price:"Selling Price",
       ar_fare_media_type_name:" Fare Media Type (AR) ",
            ar_issuer_name:" Issuer (AR) ",
            ar_org_name:" Organization (AR) "
      },
};
