// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */

import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Suspense } from 'react'
import { Link, NavLink, useHistory } from "react-router-dom";
//import NavBar from '../components/NavBar';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import "../style.css";
//import Sidebar from '../components/Sidebar';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import SearchIcon from '@mui/icons-material/Search';
import cookies from 'js-cookie'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import Loading from "../images/loading2.svg";
import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  CircularProgress,
  Popover,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

} from '@material-ui/core';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { TextCell } from 'app/components/shared/table';

import { default as ReactSelect } from "react-select";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { components } from "react-select";
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useGetFilterOrganizations } from 'hooks';
import { useGetFilterGroups } from 'hooks/useGetFilterGroups';
import { useTypedSelector } from 'helpers';
import { useSelector } from 'react-redux';
import { log } from 'console';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { hasWriteAccessToMasterConfigSubSystem } from "selectors";

const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);
const MerchantPOSTable: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true); // Loading state
  //const history.push = useNavigate();
  const { t } = useTranslation()
  const history = useHistory();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
  //req
  const [mainApiResponseData, setMainApiResponseData] = useState<any[]>([]);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [pageCount, setPageCount] = useState<any>(0);
  const [dataCountNumber, setDataCountNumber] = useState(null);
  const [status, setStatus] = useState(1);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [finalSearchQuery, setfinalSearchQuery] = useState(null);
  let loggedInUserPersonalData: any = '';
  let loggedInUserAccessTokenClientUid: any = '';

  const authData = localStorage.getItem("authData");

  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    loggedInUserPersonalData = parsedAuthData.userData;
    loggedInUserAccessTokenClientUid = parsedAuthData.userToken;

  }


  useEffect(() => {

    const makeRequest = () => {
      let url_used = ''
      if (finalSearchQuery != null) {
        url_used = (`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/getAllMerchantPOSs?page=${page}&pageSize=${limit}&searchQuery=${searchQuery.trim()}`)
      } else {
        url_used = (`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/getAllMerchantPOSs?status=${status}&page=${page}&pageSize=${limit}`)
      }
      setIsLoading(true); // Set loading to true when data is being fetched
      axios.get(url_used,

        { headers: { 'access-token': loggedInUserAccessTokenClientUid['access-token'], 'uid': loggedInUserAccessTokenClientUid.uid, 'client': loggedInUserAccessTokenClientUid.client } })
        .then((res) => {

          setMainApiResponseData(res.data.data.result)
          setDataCountNumber(res.data.data.totalCount)
          setPageCount(res.data.data.totalPages);
          setIsLoading(false); // Set loading to false when data is fetched

        })
        .catch((err) => {
          console.log("API ERROR");
          toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #r1`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
          setIsLoading(false); // Set loading to false when data is fetched

        })
      //}, 3000);
    }
    makeRequest();


  }, [page, limit, status, deleteResponse, finalSearchQuery])
  function handlePrevious() {
    setPage((p) => {
      if (p === 1) return p;
      return p - 1;
    });
  }

  function handleNext() {
    setPage((p) => {
      if (p === pageCount) return p;
      return p + 1;
    });
  }
  function handleChangeLimit(lim) {
    setLimit(lim);
    setPage(1)
  }
  function changeStatus(s) {
    setStatus(s);
    setPage(1)
  }
  const hasWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'MerchantPOS')
  );
  const moveToAddPage = (id) => {
    history.push(`/CardsManagement/merchantPOS/new`);
  }
  const moveToEditPage = (id, event) => {
    event.stopPropagation(); // Stop event propagation
    history.push(`/CardsManagement/merchantPOS/${id}/edit`);
  }
  const searchBtn = (e) => {
    e.preventDefault();
    setfinalSearchQuery(searchQuery)
    setPage(1)
  }
  const clearSearchBtn = () => {
    setfinalSearchQuery(null)
    setStatus(1);
    setPage(1)
    setSearchQuery('')
  }
  //r.email,r.pass,r.pos_serial_number,r.sam_uid,r.sim_msidn,r.merchent_id,r.user_name,r.user_limit,r.personid,e      
  const deleteMerchantPOS = async (pos_name, pos_pass, pos_serial_number, sam_UID, sim_MSIDN, merchent_ID, pos_user_name, pos_user_limit, id, event) => {
    event.stopPropagation(); // Stop event propagation
    setIsLoading(true);

    try {
      let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/updateMerchantPOS`;
      const response = await axios.post(url, {
        Email: pos_name,
        Pass: pos_pass,
        pos_Serial_number: pos_serial_number,
        sam_UID: sam_UID,
        sim_MSIDN: sim_MSIDN,
        merchent_ID: merchent_ID,
        USER_NAME: pos_user_name,
        USER_LIMIT: pos_user_limit,
        STATUS: 3,
        PersonID: id
      }, {
        headers: {
          'access-token': loggedInUserAccessTokenClientUid?.access_token,
          'uid': loggedInUserAccessTokenClientUid?.uid,
          'client': loggedInUserAccessTokenClientUid?.client,
        },
      });
      setDeleteResponse(response.data)
      toast.success('Merchant POS Deleted successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        toastStyle: {
          backgroundColor: '#F1662D',
          color: 'white',
        },
      });


    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {

        toast.error(`Failed to Delete merchant POS[${error.response.data.message}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {

        toast.error(`Failed to Delete merchant POS  [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const tableRowClickNavigation = (id) => {
    history.push(`/CardsManagement/merchantPOS/${id}`);
  }

  const startItem = (page - 1) * limit + 1;
  const endItem = Math.min(page * limit, dataCountNumber || 0);
  return (
    <div className='dev-contain-all-page-content'>
      <h2 className='header-of-subsystem-page'>
        <AdUnitsIcon style={{ margin: "10px  10px 13px 10px", fontSize: "28px" }} />
        {intl.formatMessage({ id: 'MerchantPOS' })}
      </h2>
      {hasWriteAccess && <button className="styleAddBtn" style={
        currentLanguageCode == "en-us" ?
          { right: "90px" } :
          { left: "90px" }
      }
        onClick={() => history.push(`/CardsManagement/merchantPOS/new`)}
      >
        {intl.formatMessage({ id: 'AddNewMerchantPOS' })}
      </button>}
      {loggedInUserPersonalData && loggedInUserAccessTokenClientUid ? <>
        <div className='table-controller-sec' >
          <div style={{ display: "flex", marginTop: "0px", marginBottom: "0px" }}>
            <form onSubmit={searchBtn} className="input-search">
              <input type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={intl.formatMessage({ id: 'SearchByPOSName' })}
              />

              <button style={
                currentLanguageCode == "en-us" ?
                  { right: "8px" } : { left: "8px" }

              }
                type="submit"
              >
                <SearchIcon style={{ fontSize: "22px" }} />
              </button>
            </form>
            {finalSearchQuery && <button className="cancelBtnStyle" style={{ marginTop: "0px", marginBottom: "15px" }}
              onClick={() => clearSearchBtn()}
            >
              <ClearIcon style={{ fontSize: "22px", padding: "0" }} />
            </button>}
          </div>


          <div style={{ direction: "ltr" }}>
          {currentLanguageCode == "en-us" ?
                <>
                  {finalSearchQuery ? <></> : <span style={{ margin: "10px", direction: "ltr" }}><bdi>
                    <span className='show-span' style={{ margin: "10px", direction: "ltr" }}> <bdi>
                      {intl.formatMessage({ id: 'status' })}  </bdi>
                    </span>
                    <select className='show-select' style={{ direction: "ltr" }} onChange={(e) => changeStatus(e.target.value)}>

                      <option value="1">{currentLanguageCode == "en-us" ? "Active" : "مفعل"}</option>
                      <option value="2">{currentLanguageCode == "en-us" ? "Blocked" : "محظور"}</option>
                      <option value="3">{currentLanguageCode == "en-us" ? "Deleted" : "محذوف"}</option>

                    </select></bdi>
                  </span>}
                  {mainApiResponseData?.length > 0 ? <>  <span style={{ margin: "10px", direction: "ltr" }}>
                    {page}
                    <span className='show-span'>
                      <bdi style={{ margin: "10px" }}>
                        {intl.formatMessage({ id: 'From' })}
                      </bdi>
                    </span>
                    {pageCount}
                  </span>  </> : <></>}
                </>
                : <>
                  {finalSearchQuery ? <></> : <span style={{ margin: "10px", direction: "ltr" }}><bdi>
                    <span className='show-span' style={{ margin: "10px", direction: "ltr" }}> <bdi>
                      {intl.formatMessage({ id: 'status' })}  </bdi>
                    </span>
                    <select className='show-select' style={{ direction: "ltr" }} onChange={(e) => changeStatus(e.target.value)}>

                      <option value="1">{currentLanguageCode == "en-us" ? "Active" : "مفعل"}</option>
                      <option value="2">{currentLanguageCode == "en-us" ? "Blocked" : "محظور"}</option>
                      <option value="3">{currentLanguageCode == "en-us" ? "Deleted" : "محذوف"}</option>

                    </select></bdi>
                  </span>}
                  {mainApiResponseData?.length > 0 ? <><span style={{ margin: "10px", direction: "ltr" }}>
                    {pageCount}
                    <span className='show-span'>
                      <bdi style={{ margin: "10px" }}>
                        {intl.formatMessage({ id: 'From' })}
                      </bdi>
                    </span>
                    {page}
                  </span>  </> : <></>}</>}
            {mainApiResponseData?.length > 0 ? <>
          
              <button className='page-navigation-arrow' disabled={currentLanguageCode == "en-us" ? page === 1 : page === pageCount} onClick={currentLanguageCode == "en-us" ? handlePrevious : handleNext}>
                <span style={{ direction: "ltr" }}>
                  <KeyboardArrowLeftIcon style={{ fontSize: "30px" }} />
                </span>
              </button>
              <bdi> {startItem} - {endItem} {intl.formatMessage({ id: 'Of' })}  {dataCountNumber} </bdi>
              <button className='page-navigation-arrow' disabled={currentLanguageCode == "en-us" ? page === pageCount : page === 1} onClick={currentLanguageCode == "en-us" ? handleNext : handlePrevious}>
                <span style={{ direction: "ltr" }}>
                  <KeyboardArrowRightIcon style={{ fontSize: "30px" }} />
                </span>
              </button>
            </> : <></>}
            <span style={{ margin: "10px" }}>
              <bdi>
                <span className='show-span' style={{ margin: "10px" }}>
                  {intl.formatMessage({ id: 'Show' })}
                </span>
                <select className='show-select' onChange={(e) => handleChangeLimit(e.target.value)}>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </bdi>
            </span>
          </div>
        </div></> : <></>}
      {loggedInUserPersonalData && loggedInUserAccessTokenClientUid ?
        <div className="Table-Wrapper  " >

          <Table
            className={classes.table}
            size="small"
            aria-label="zoning Routes Table"
          >
            <TableHead style={{ border: "0" }}>
              <TableRow style={{ border: "0" }}>

                <TableCell align="center">
                  {intl.formatMessage({ id: 'POSName' })}
                </TableCell>
                <TableCell align="center">
                  {intl.formatMessage({ id: 'MerchantPOSName' })}
                </TableCell>
                <TableCell align="center">
                  {intl.formatMessage({ id: 'MerchantName' })}
                </TableCell>
                <TableCell align="center">
                  <th> {intl.formatMessage({ id: 'status' })}</th>
                </TableCell>

                {hasWriteAccess && <><TableCell align="center">
                  {intl.formatMessage({ id: 'actions' })}
                </TableCell></>}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                isLoading ? (
                  <tr> <td colSpan={5} style={{ textAlign: "center", padding: "15px" }}>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <CircularProgress style={{ width: "50px" }} />
                    </div>
                  </td></tr>
                ) :

                  mainApiResponseData?.length > 0 ?
                    mainApiResponseData?.map((r, i) =>

                      <TableRow key={r.personid} onClick={() => tableRowClickNavigation(r.personid)} className={classes.row}>
                        <TextCell
                          label={r.user_name}
                          align="center"
                          style={{ fontFamily: 'Cairo' }}
                        />
                        <TextCell
                          label={r.email}
                          align="center"
                          style={{ fontFamily: 'Cairo' }}
                        />
                        <TextCell
                          label={r.merchant_name}
                          align="center"
                          style={{ fontFamily: 'Cairo' }}
                        />
                        <TextCell
                          label={r.status == 1 ? <CheckCircleIcon style={{ color: "#81D135", margin: " 10px" }} /> : r.status == 2 ? <CheckCircleIcon style={{ color: "black", margin: "10px" }} /> : <CheckCircleIcon style={{ color: "red", margin: "10px" }} />}
                          style={{ fontFamily: 'Cairo' }}
                        />
                        {hasWriteAccess && <>
                          <TextCell
                            label={r.status == 3 ? <> <EditIcon className="editAndDeleteIcon" onClick={(e) => moveToEditPage(r.personid, e)} /></> :
                              <><EditIcon className="editAndDeleteIcon" onClick={(e) => moveToEditPage(r.personid, e)} />
                                <><button className="delBtn editAndDeleteIcon" data-bs-toggle="modal" onClick={(e) => e.stopPropagation()} data-bs-target={"#exampleModal3" + r.personid}><DeleteIcon /></button>
                                  <div className="modal fade" style={{ zIndex: "9999969" }} id={"exampleModal3" + r.personid} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-header">

                                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                          <h5>  {currentLanguageCode == "en-us" ? `Are You Sure You Want To Delete ${r.user_name}` : `هل أنت متأكد انك تريد حذف ${r.user_name}`}</h5>
                                        </div>
                                        <div className="modal-footer" style={{ direction: currentLanguageCode == "en-us" ? "ltr" : "rtl" }}>
                                          <button type="button" onClick={(e) => e.stopPropagation()} className="btn btn-outline-dark" data-bs-dismiss="modal">{intl.formatMessage({ id: 'Cancel' })} </button>
                                          <button type="button"
                                            className="btn btn-danger" data-bs-dismiss="modal"

                                            onClick={(e) => deleteMerchantPOS(r.email, r.pass, r.pos_serial_number, r.sam_uid, r.sim_msidn, r.merchent_id, r.user_name, r.user_limit, r.personid, e)}>{intl.formatMessage({ id: 'Delete' })} </button>

                                        </div>
                                      </div>
                                    </div>
                                  </div></></>}
                            align="center"
                            style={{ fontFamily: 'Cairo' }}
                          />     </>}

                      </TableRow>)
                    : <tr> <td colSpan={5} style={{ textAlign: "center", padding: "15px" }}>{intl.formatMessage({ id: 'NoDateAvailable' })}</td></tr>
              }
            </TableBody>
          </Table>
        </div> : <h2>
          <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
        </h2>}


    </div>
  )
}

export default MerchantPOSTable