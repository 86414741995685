// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const AddFareMedia = () => {
  const currentLanguageCode = localStorage.getItem('EASY_MOBILITY_LANGUAGE') || 'en-us';
  const intl = useIntl();
  const history = useHistory();
  const [fareMediaTypes, setFareMediaTypes] = useState([]);
  const [fareMediaIssuers, setFareMediaIssuers] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [fareMediaUID, setFareMediaUID] = useState('');
  const [fareMediaSerial, setFareMediaSerial] = useState('');
  const [fareMediaTypeId, setFareMediaTypeId] = useState('');
  const [initBalance, setInitBalance] = useState('');
  const [fareMediaIssuerId, setFareMediaIssuerId] = useState('');
  const [organisationId, setOrganisationId] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGetDataLoading, setIsGetDataLoading] = useState(false);
  const [error, setError] = useState(null);

  let userAuthData = '';
  const authData = localStorage.getItem('authData');
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userAuthData = parsedAuthData.userToken;
  }

  const fetchFareMediaTypes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_fare_media_types`, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setFareMediaTypes(response.data.data);
    } catch (error) {
      toast.error(`Failed to get fare media types [${error}]`);
    }
  };

  const fetchFareMediaIssuers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_fare_media_issuers`, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setFareMediaIssuers(response.data.data);
    } catch (error) {
      toast.error(`Failed to get fare media issuers [${error}]`);
    }
  };

  const fetchOrganisations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_organisations`, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setOrganisations(response.data.data);
    } catch (error) {
      toast.error(`Failed to get organisations [${error}]`);
    }
  };

  useEffect(() => {
    setIsGetDataLoading(true)
    fetchFareMediaTypes();
    fetchFareMediaIssuers();
    fetchOrganisations();
    setIsGetDataLoading(false)
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
 
    setIsLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/add_new_fare_media`;
      await axios.post(
        url,
        {
          fare_media_uid: fareMediaUID.trim(),
          fare_media_serial: fareMediaSerial.trim(),
          fare_media_type_id: fareMediaTypeId,
          init_balance: parseFloat(initBalance),
          fare_media_issuer_id: fareMediaIssuerId,
          organisation_id: organisationId,
          selling_price: parseFloat(sellingPrice),
          is_blacklist: isBlacklisted,
        },
        {
          headers: {
            'access-token': userAuthData?.access_token,
            uid: userAuthData?.uid,
            client: userAuthData?.client,
          },
        }
      );
      toast.success('Fare Media added successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      setTimeout(() => {
        history.push('/CardsManagement/fareMedia/');
      }, 250);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {

        toast.error(`Failed to Add Fare Media [${error.response.data.message}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {

        toast.error(`Failed to Add Fare Media  [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: -5, padding: 4 }}>
        <Typography sx={{ color: '#252733', fontWeight: 'bold', marginBottom: '16px' }}>
          {intl.formatMessage({ id: 'AddNewFareMedia' })}
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <div className='parent-form'>
            <div>
              <label className='labelStyle'>{intl.formatMessage({ id: 'uid' })}</label>
              <span style={{color:"red"}}> * </span>
              <input
                type="text"
                className='inputStyle'
                value={fareMediaUID}
                onChange={(e) => setFareMediaUID(e.target.value)}
                required
              />
              
              <label className='labelStyle'>{intl.formatMessage({ id: 'serial_num' })}</label>
              <span style={{color:"red"}}> * </span>
              <input
                type="text"
                className='inputStyle'
                value={fareMediaSerial}
                onChange={(e) => setFareMediaSerial(e.target.value)}
                required
              />

              <label className='labelStyle'>{intl.formatMessage({ id: 'init_balance' })}</label>
              <span style={{color:"red"}}>*</span>
              <input
                type="number"
                min="0"
                className='inputStyle'
                value={initBalance}
                onChange={(e) => setInitBalance(e.target.value)}
                required
              />

              <label className='labelStyle'>{intl.formatMessage({ id: 'SellingPrice' })}</label>
              <span style={{color:"red"}}> * </span>
              <input
                type="number"
                min="0"
                className='inputStyle'
                value={sellingPrice}
                onChange={(e) => setSellingPrice(e.target.value)}
                required
              />
              
              <label className='labelStyle'
              >{intl.formatMessage({ id: 'is_blacklisted' })}</label>
              <input
               style={{margin:"10px -5px",padding:"10px",width:"40px"}}
                type="checkbox"
                checked={isBlacklisted}
                onChange={(e) => setIsBlacklisted(e.target.checked)}
              />
            </div>

            <div>
              <label className='labelStyle'>{intl.formatMessage({ id: 'fare_media_type_name' })}</label>
              <span style={{color:"red"}}> * </span>
              {isGetDataLoading? (
                <CircularProgress size={24} sx={{ display: "block" }} />
              ) : (
                <select
                  className='inputStyle'
                  value={fareMediaTypeId}
                  onChange={(e) => setFareMediaTypeId(e.target.value)}
                  required
                >
                  <option value="" style={{ fontWeight: "bold" }}>-----------</option>
                  {fareMediaTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {currentLanguageCode == "en-us" ? type.en_name : type.ar_name }
                    </option>
                  ))}
                </select>
              )}

              <label className='labelStyle'>{intl.formatMessage({ id: 'issuer_name' })}</label>
              <span style={{color:"red"}}> * </span>
              {isGetDataLoading ? (
                <CircularProgress size={24} sx={{ display: "block" }} />
              ) : (
                <select
                  className='inputStyle'
                  value={fareMediaIssuerId}
                  onChange={(e) => setFareMediaIssuerId(e.target.value)}
                  required
                >
                  <option value="" style={{ fontWeight: "bold" }}>-----------</option>
                  {fareMediaIssuers.map((issuer) => (
                    <option key={issuer.id} value={issuer.id}>
                      {currentLanguageCode == "en-us" ?  issuer.en_issuer_name : issuer.ar_issuer_name}
                    </option>
                  ))}
                </select>
              )}

              <label className='labelStyle'>{intl.formatMessage({ id: 'org_name' })}</label>
              <span style={{color:"red"}}> * </span>
              { isGetDataLoading ? (
                <CircularProgress size={24} sx={{ display: "block" }} />
              ) : (
                <select
                  className='inputStyle'
                  value={organisationId}
                  onChange={(e) => setOrganisationId(e.target.value)}
                  required
                >
                  <option value="" style={{ fontWeight: "bold" }}>-----------</option>
                  {organisations.map((organisation) => (
                    <option key={organisation.id} value={organisation.id}>
                      {currentLanguageCode == "en-us" ? organisation.en_name : organisation.ar_name}
                    </option>
                  ))}
                </select>
              )}

            </div>
          </div>

          <div sx={{ marginBottom: 2, display: "block" }}>
            <button
              type="submit"
              className='updateBtnStyle'
              disabled={isLoading}
            >
              {isLoading ? intl.formatMessage({ id: 'Adding' }) : intl.formatMessage({ id: 'AddFareMedia' })}
            </button>
            <button
              className='cancelBtnStyle'
              onClick={() => history.push('/CardsManagement/fareMedia/')}
            >
              {intl.formatMessage({ id: 'Back' })}
            </button>
          </div>
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
};

export default AddFareMedia;

