import React, { useContext } from 'react';

import {
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StyleIcon from '@mui/icons-material/Style';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { logout } from 'actions';
import HomeLogo from 'assets/logo_with_name_side.png';
import { SideNavContext } from 'context/side-nav.context';
import { useTypedSelector } from 'helpers';
import {
  canReadFinancialControl,
  canReadMasterConfig,
  canReadOperations,
  canViewOperationsSubSystems,
  hasReadAccessToMasterConfigSubSystem,
  canViewFinancialControlSubSystems,
  canReadAdminManagement,
  canViewAdminsSubSystems,
  canReadZoning,
  canViewZoningSubSystems,
  canViewFaresSubSystems,
  canReadFares
} from 'selectors';

import { ReactComponent as DevicesIcon } from './icons/devices-icon.svg';
import { ReactComponent as DriversIcon } from './icons/drivers-icons.svg';
import { ReactComponent as GroupsIcon } from './icons/groups-icon.svg';
import { ReactComponent as LogoutIcon } from './icons/logout-icon.svg';
import { ReactComponent as RoutesIcon } from './icons/routes-icon.svg';
import { ReactComponent as StationsIcon } from './icons/stations-icon.svg';
import { ReactComponent as VehicleClassesIcon } from './icons/vehicleClasses-icon.svg';
import { ReactComponent as VehiclesIcon } from './icons/vehicles-icon.svg';
import { ReactComponent as VehicleTypesIcon } from './icons/vehicleTypes-icon.svg';

import { ReactComponent as TripSalesIcon } from 'assets/comment-dollar.svg';

const drawerWidth = 230;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navList: {
      background: '#363740',
      flex: 1,
      paddingTop: 40,
    },
    navHeader: { display: 'flex', marginBottom: 60 },
    navSection: {
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: '1px solid rgba(223, 224, 235, .2)',
    },
    drawerPaper: {
      width: drawerWidth,
      scrollbarWidth: 'none' /* Firefox */,
      '-ms-overflow-style': 'none' /* IE 10+ */,
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
      },
    },
    nestedNav: { paddingLeft: theme.spacing(4) },
    navItem: {
      height: 52,
    },
    activeItem: {
      borderLeft: '3px solid #FF6B38',
      background: 'rgba(159, 162, 180, .1)',
    },
    idleItem: {
      borderLeft: '3px solid #363740',
    },
    logo: {
      width: 195,
    },
  })
);

const getIconFillColor = (isActive: boolean) => (isActive ? '#fff' : '#60626E');

const getIconStyle = (isActive: boolean) =>
  isActive
    ? {}
    : {
        opacity: 0.3,
        filter:
          'invert(37%) sepia(19%) saturate(237%) hue-rotate(194deg) brightness(98%) contrast(89%);',
      };

const iconsReference = (key: string, isActive: boolean): JSX.Element => {
  const fill = getIconFillColor(isActive);
  const style = getIconStyle(isActive);

  const icons = {
    'Super Dash': <HomeIcon fill={fill} />,
    Configuration: <SettingsIcon fill={fill} />,
    Organizations: <BusinessIcon fill={fill} />,
    CustomerSupport: <SupportAgentIcon fill={fill} />,
    MerchantPOS: <AdUnitsIcon fill={fill} />,
    Merchants: <BlurCircularIcon fill={fill} />,
    ChargingTransactions: <MonetizationOnIcon fill={fill} />,
    FareMedia: <StyleIcon fill={fill} />,
    Admins: <PersonIcon fill={fill} />,
    Operations: <DirectionsBusIcon fill={fill} />,
    Routes: <RoutesIcon fill={fill} />,
    Drivers: <DriversIcon fill={fill} />,
    Devices: <DevicesIcon style={style} />,
    Groups: <GroupsIcon fill={fill} />,
    TripSales: <TripSalesIcon fill={fill} />,
    Vehicles: <VehiclesIcon fill={fill} />,
    VehicleTypes: <VehicleTypesIcon fill={fill} />,
    VehicleClasses: <VehicleClassesIcon fill={fill} />,
    Stations: <StationsIcon fill={fill} />,
  };

  return icons[key];
};

const SideNavLink: React.FC<{
  title: string;
  path: string;
  iconName: string;
  fontSize?: number;
}> = props => {
  const classes = useStyles();
  const location = useLocation();
  const { title, path, iconName, fontSize } = props;
  const { handleDrawerToggle } = useContext(SideNavContext);
  const isActive = location.pathname.indexOf(path) === 0;
  const textColor = isActive ? '#fff' : '#A4A6B3';

  return (
    <>
      <ListItem
        button
        component={Link}
        to={path}
        onClick={() => {
          if (handleDrawerToggle) {
            handleDrawerToggle();
          }
        }}
        className={`${isActive ? classes.activeItem : classes.idleItem} ${
          classes.navItem
        }`}
      >
        <ListItemIcon
          style={{
            color: getIconFillColor(isActive),
          }}
        >
          {iconsReference(iconName, isActive)}
        </ListItemIcon>

        <ListItemText
          primary={title}
          primaryTypographyProps={{ style: { fontSize } }}
          style={{ color: textColor }}
        />
      </ListItem>
    </>
  );
};

const SideNav: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isHomeRoute = pathname === '/super-dash';
  const isAdminManagementRoute = pathname.includes('/adminManagement');
  const isOperationsRoute = pathname.includes('/operations');
  const isConfigurationsRoute = pathname.includes('/configuration');
  const isFinancialControlRoute = pathname.includes('/financialControl');
  const isZoningRoute = pathname.includes('/zoning');
  const isCustomerSupport = pathname.includes('/customerSupport');
  const isCardsManagement = pathname.includes('/CardsManagement');
  const showDesktopSideNav = useMediaQuery(theme.breakpoints.up('sm'));

  const { isMobileSideNavOpen, handleDrawerToggle, showSideNav } =
    useContext(SideNavContext);

  const canViewMasterConfig = useTypedSelector(state =>
    canReadMasterConfig(state)
  );

  const canViewAdminManagement = useTypedSelector(canReadAdminManagement);
  const canViewOperations = useTypedSelector(canReadOperations);
  const canViewFinancialControl = useTypedSelector(canReadFinancialControl);
  const canViewZoning = useTypedSelector(canReadFares);
  const canViewOrganizations = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'Organizations')
  );
  const canViewCustomerSupport = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'CustomerSupport')
  );
  //make CardsManagement can view mechanesm
  let canViewMerchants = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'Merchants')
  );
  let canViewMerchantPOS = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'MerchantPOS')
  );
  let canViewChargingTransactions = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'ChargingTransactions')
  );
  let canViewFareMedia = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'FareMedia')
  );
  const canViewCardsManagement = canViewMerchants || canViewMerchantPOS || canViewChargingTransactions || canViewFareMedia ? true : false
  
  const canViewAdmins = useTypedSelector(state =>
    canViewAdminsSubSystems(state, 'Admins')
  );

  const canViewRoutes = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Routes')
  );

  const canViewDrivers = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Drivers')
  );

  const canViewGroups = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Groups')
  );

  const canViewVehicles = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Vehicles')
  );

  const canViewStations = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Stations')
  );

  const canViewDevices = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Devices')
  );

  const canViewTripSales = useTypedSelector(state =>
    canViewFinancialControlSubSystems(state, 'TripPaymentOperations')
  );
  const canViewZoningRoutes = useTypedSelector(state =>
    canViewFaresSubSystems(state, 'Fares','Tickets')
  );
  const drawer = (
    <List className={classes.navList} style={{position:"relative"}}>
      <header className={classes.navHeader}>
        <Link to="/" style={{ margin: '0 18px' }}>
          <img src={HomeLogo} alt="Home" className={classes.logo} />
        </Link>
      </header>

      <section className={classes.navSection}>
        {isConfigurationsRoute && canViewMasterConfig && (
          <>
            {canViewOrganizations && (
              <SideNavLink
                title={intl.formatMessage({ id: 'organizations' })}
                path="/configuration/organizations"
                iconName="Organizations"
              />
            )}
   
          </>
        )}
          {isCustomerSupport && canViewCustomerSupport && (
              <SideNavLink
                title={intl.formatMessage({ id: 'customerSupport' })}
                path="/customerSupport/CustomerSupportHome"
                iconName="CustomerSupport"
              />
            )}
           {isAdminManagementRoute && canViewAdminManagement && (
          <>
            {canViewAdmins && (
              <SideNavLink
                title={intl.formatMessage({ id: 'admins' })}
                path="/adminManagement/admins"
                iconName="Admins"
              />
            )}
          </>
        )}
        {isCardsManagement && canViewCardsManagement && (
          <>
            {canViewMerchants && (
              <SideNavLink
                title={intl.formatMessage({ id: 'Merchants' })}
                path="/CardsManagement/merchants"
                iconName="Merchants"
              />
            )}
             {canViewMerchantPOS && (
              <SideNavLink
                title={intl.formatMessage({ id: 'MerchantPOS' })}
                path="/CardsManagement/merchantPOS"
                iconName="MerchantPOS"
              />
            )}
             {canViewChargingTransactions && (
              <SideNavLink
                title={intl.formatMessage({ id: 'ChargingTransactions' })}
                path="/CardsManagement/chargingTransactions"
                iconName="ChargingTransactions"
              />
            )}
               {canViewFareMedia && (
              <SideNavLink
                title={intl.formatMessage({ id: 'FareMedia' })}
                path="/CardsManagement/fareMedia"
                iconName="FareMedia"
              />
            )}
          </>
        )}
          {isZoningRoute && canViewZoning && (
          <>
            {canViewZoningRoutes && (
              <SideNavLink
                title={intl.formatMessage({ id: 'routes' })}
                path="/zoning/zoningRoutes"
                iconName="Routes"
              />
            )}
          </>
        )}

        {isOperationsRoute && canViewOperations && (
          <>
            {canViewRoutes && (
              <SideNavLink
                title={intl.formatMessage({ id: 'routes' })}
                path="/operations/routes"
                iconName="Routes"
              />
            )}

            {canViewDrivers && (
              <SideNavLink
                title={intl.formatMessage({ id: 'drivers' })}
                path="/operations/drivers"
                iconName="Drivers"
              />
            )}

            {canViewDevices && (
              <SideNavLink
                title={intl.formatMessage({ id: 'devices' })}
                path="/operations/devices"
                iconName="Devices"
              />
            )}

            {canViewGroups && (
              <SideNavLink
                title={intl.formatMessage({ id: 'groups' })}
                path="/operations/groups"
                iconName="Groups"
              />
            )}

            {canViewVehicles && (
              <SideNavLink
                title={intl.formatMessage({ id: 'vehicles' })}
                path="/operations/vehicles"
                iconName="Vehicles"
              />
            )}

            {canViewVehicles && (
              <SideNavLink
                title={intl.formatMessage({ id: 'vehicleTypes' })}
                path="/operations/vehicle-types"
                iconName="VehicleTypes"
              />
            )}

            {canViewVehicles && (
              <SideNavLink
                title={intl.formatMessage({ id: 'vehicleClasses' })}
                path="/operations/vehicle-classes"
                iconName="VehicleClasses"
              />
            )}

            {canViewStations && (
              <SideNavLink
                title={intl.formatMessage({ id: 'stations' })}
                path="/operations/stations"
                iconName="Stations"
              />
            )}
          </>
        )}

        {isFinancialControlRoute && canViewFinancialControl && (
          <>
            {canViewTripSales && (
              <SideNavLink
                title={intl.formatMessage({ id: 'tripSales' })}
                path="/financialControl/tripSales"
                iconName="TripSales"
              />
            )}
          </>
        )}
      </section>

      <ListItem button onClick={() => dispatch(logout())}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>

        <ListItemText
          primary={intl.formatMessage({ id: 'logout' })}
          style={{ color: '#A4A6B3' }}
        />
      </ListItem>
      <h6 style={{position:"absolute",bottom:"10px",padding:"0 66px",color:"white",textAlign:"center",fontSize:"13px"}}>{process.env.REACT_APP_WORKING_VERSION_NUMBER}</h6>
    </List>
  );

  const container = window ? () => window.document.body : undefined;

  if (isHomeRoute || !showSideNav) {
    return null;
  }

  return (
    <nav className={classes.drawer} aria-label="Navigation sections">
      {showDesktopSideNav ? (
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={isMobileSideNavOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      )}
    </nav>
  );
};

export { SideNav };
