// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';
import { useTypedSelector } from 'helpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@material-ui/core';
const ViewFareMedia = () => {
  const [merchantName, setMerchantName] = useState('');
  const [merchantEnName, setMerchantEnName] = useState('');
  const [merchent_ID, setMerchantId] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const [merchantStatus, setMerchantStatus] = useState(null);
  const [pos_user_name, setPosUserName] = useState('');
  const [pos_name, setPosName] = useState('');
  const [pos_pass, setPosPass] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pos_serial_number, setPosSerialNumber] = useState('');
  const [sam_UID, setSamUID] = useState('');
  const [sim_MSIDN, setSimMSIDN] = useState('');
  const [pos_user_limit, setUserLimit] = useState('');
  const [merchant_pos_status, setMerchantPosStatus] = useState(1);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us';
  const intl = useIntl();
  const history = useHistory();
  const [deleteResponse, setDeleteResponse] = useState(null); 
  let userPersonalData = '';
  let userAuthData = '';
  const authData = localStorage.getItem("authData");
  const [showPassword, setShowPassword] = useState(false);
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userPersonalData = parsedAuthData.userData;
    userAuthData = parsedAuthData.userToken;
  }

  // Fetch merchant data
  useEffect(() => {
    const fetchMerchantPOS = async () => {
      setIsFormLoading(true);
      setError(null);
      try {
        let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/getMerchantPOS`;
        const response = await axios.post(url, {
           PersonID: id 
        }, {
            headers: {
              'access-token': userAuthData?.access_token,
              'uid': userAuthData?.uid,
              'client': userAuthData?.client,
            },
          });
          const merchantPOS = response.data.data;
          setPosName(merchantPOS.email); 
          setPosPass(merchantPOS.pass);
          setPosSerialNumber(merchantPOS.pos_serial_number);
          setSamUID(merchantPOS.sam_uid);
          setSimMSIDN(merchantPOS.sim_msidn);
          setMerchantId(merchantPOS.merchent_id);
          setPosUserName(merchantPOS.user_name);
          setUserLimit(merchantPOS.user_limit);
          setMerchantPosStatus(merchantPOS.status);
          setMerchantName(merchantPOS.merchant_name);
          setMerchantEnName(merchantPOS.merchant_en_name);
          setMerchantStatus(merchantPOS.merchant_status);
      } catch (error) {
        setError(`Failed to fetch merchant POS [${error}]`);
        toast.error(`Failed to fetch merchant POS [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } finally {
        setIsFormLoading(false);
      }
    };

    fetchMerchantPOS();
  }, [deleteResponse]);

  const hasWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'MerchantPOS')
  );
  const deleteMerchantPOS = async (pos_name, pos_pass, pos_serial_number, sam_UID, sim_MSIDN, merchent_ID, pos_user_name, pos_user_limit, id) => {
    setIsLoading(true);
    try {
      let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/updateMerchantPOS`;
      const response = await axios.post(url, {
        Email: pos_name,
        Pass: pos_pass,
        pos_Serial_number: pos_serial_number,
        sam_UID: sam_UID,
        sim_MSIDN: sim_MSIDN,
        merchent_ID: merchent_ID,
        USER_NAME: pos_user_name,
        USER_LIMIT: pos_user_limit,
        STATUS: 3,
        PersonID: id
      }, {
        headers: {
          'access-token': userAuthData?.access_token,
          'uid': userAuthData?.uid,
          'client': userAuthData?.client,
        },
      });
      setDeleteResponse(response.data)
      toast.success('Merchant POS Deleted successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        toastStyle: {
          backgroundColor: '#F1662D',
          color: 'white',
        },
      });


    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {

        toast.error(`Failed to Delete merchant POS[${error.response.data.message}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {

        toast.error(`Failed to Delete merchant POS  [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Box sx={{  marginTop: -5, padding: 4 }}>
        <Typography sx={{ color: '#252733',fontWeight:"bold",marginBottom:"16px" }}>
          {intl.formatMessage({ id: 'MerchantPOSInfo' })}
        </Typography>
        
        {isFormLoading ? (
          <CircularProgress  style={{display:"block"}}/>
        ) : (

          <>
            <div className='table-view-item-info'>
                <div className="name-and-actions-btns-sec">
                    <h5 className="title-item-sec">
                        {pos_user_name}
                    </h5>
                    <div className="actions-btns-sec">
                    {hasWriteAccess && 
                        <> <button className="styleAddBtn" style={{position:"inherit",marginTop:"-10px",paddingBottom:"7px",paddingTop:"8px"}}  onClick={()=>history.push(`/CardsManagement/merchantPOS/${id}/edit`)}>
                           <EditIcon style={{fontSize:"24px",paddingRight:"3px",paddingLeft:"3px"}} /> {intl.formatMessage({ id: 'edit' })}
                        </button>
                        {merchant_pos_status !=3 &&<><button  className="styleAddBtn"  data-bs-toggle="modal" style={{backgroundColor:"#D54C5E",position:"inherit",marginLeft:"5px",marginRight:"5px",marginTop:"-10px",paddingBottom:"7px",paddingTop:"8px"}} 
                           data-bs-target={"#exampleModal4"+ id }>
                          <DeleteIcon style={{fontSize:"24px",paddingRight:"3px",paddingLeft:"3px"}}/> {intl.formatMessage({ id: 'Delete' })}  </button>
                        <div className="modal fade" style={{zIndex:"9999969"}} id={"exampleModal4"+ id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                             
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                <h5>  {currentLanguageCode == "en-us" ? `Are You Sure You Want To Delete ${pos_user_name}` : `هل أنت متأكد انك تريد حذف ${pos_user_name}`}</h5>
                                </div>
                              <div className="modal-footer" style={{direction:currentLanguageCode == "en-us" ? "ltr":"rtl"}}>
                              <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">{intl.formatMessage({ id: 'Cancel' })} </button>
                                <button type="button" 
                                className="btn btn-danger"data-bs-dismiss="modal"
                                
                              onClick={()=>deleteMerchantPOS(pos_name, pos_pass, pos_serial_number, sam_UID, sim_MSIDN, merchent_ID, pos_user_name, pos_user_limit, id)}>{intl.formatMessage({ id: 'Delete' })} </button>
                          
                              </div>
                            </div>
                          </div>
                        </div></>}</>}

                    </div>
                </div>
                <div className="data-view-sec">

                <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'POSName' })}
                        </div>
                        <div className="value-of-one-info">
                           {pos_user_name}
                        </div>
                    </div>
                    <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'Name' })}
                        </div>
                        <div className="value-of-one-info">
                           {pos_name}
                        </div>
                    </div>
                    <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'POSStatus' })}
                        </div>
                        <div className="value-of-one-info">
                           
                           {merchant_pos_status == 1 ?<CheckCircleIcon style={{color:"#81D135"}}/>:merchant_pos_status == 2 ?<CheckCircleIcon style={{color:"black"}}/>:<CheckCircleIcon style={{color:"red"}}/>}
                        </div>
                    </div>
                    <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'MerchantName' })}
                        </div>
                        <div className="value-of-one-info">
                           { merchantEnName} - { merchantName }
                        </div>
                    </div>
                    <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'MerStatus' })}
                        </div>
                        <div className="value-of-one-info">
                           
                           {merchantStatus == 1 ?<CheckCircleIcon style={{color:"#81D135"}}/>:merchantStatus == 2 ?<CheckCircleIcon style={{color:"black"}}/>:<CheckCircleIcon style={{color:"red"}}/>}
                        </div>
                    </div>
                    <div className="one-info">
                        <div className="title-of-one-info">
                         {intl.formatMessage({ id: 'POSSerialNumber' })}
                        </div>
                        <div className="value-of-one-info">
                           {pos_serial_number} 
                        </div>
                    </div> 
                    <div className="one-info">
                        <div className="title-of-one-info">
                          SAM UID
                        </div>
                        <div className="value-of-one-info">
                           {sam_UID}
                        </div>
                    </div> 
                    <div className="one-info">
                        <div className="title-of-one-info">
                           SIM MSIDN
                        </div>
                        <div className="value-of-one-info">
                           {sim_MSIDN}
                        </div>
                    </div>
                    <div className="one-info">
                        <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'UserLimit' })}
                        </div>
                        <div className="value-of-one-info">
                           {pos_user_limit}
                        </div>
                    </div>
                  
                    <div className="one-info">
                        <div className="title-of-one-info" style={{marginTop:"11px"}}>
                            {intl.formatMessage({ id: 'Password' })}
                        </div>
                        <div className="value-of-one-info">
                       
                           <div className="inputPasswordContainer">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='inputStylePassword inputStylePassword-in-view'
                    id="password"
                    value={pos_pass}
                    disabled
                    required
                  />
                  <IconButton
                  className='visibIcon'
                    onClick={() => setShowPassword(!showPassword)}
               style={currentLanguageCode === "en-us" ? {right:"33px"}:{left:"33px"}}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  </div>
                        </div>
                    </div>
                
                </div>
            </div>
       
          </>
        )}
         <button
           className='cancelBtnStyle'
           style={{marginTop:"20px"}}
            onClick={() => history.push(`/CardsManagement/merchantPOS/`)}
          >
            {intl.formatMessage({ id: 'BackToMerchantPOS' })}
          </button>
      </Box>
      <ToastContainer /></>
    
  );
};

export default ViewFareMedia;
